import { Add, AttachFile, Close, Download, Edit, ToggleOff, ToggleOn } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Menu, Typography } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridFilterModel, GridPaginationModel, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import saveAs from "file-saver";
import moment, { Moment } from "moment";
import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { fetchFileGet, LayoutContext } from "wcz-layout";
import CreateDialog from "../components/receivedMaterialPage/CreateDialog";
import EditDialog from "../components/receivedMaterialPage/EditDialog";
import ViewPhotosDialog from "../components/receivedMaterialPage/ViewPhotosDialog";
import GridServerSideModel from "../models/dataGrid/GridServerSideModel";
import ReceivingsContentModel from "../models/ReceivingsContentModel";
import { useGetReceivings } from "../services/WhReceivingService";
import { apiUrl } from "../utils/BaseUrl";

interface CustomToolbarProps {
    handleClickAddManualReceiving: () => void,
    rowSelectionModel: GridRowSelectionModel[],
    handleClickEditReceivedMaterial: (selectedReceivingRecordId: string) => void,
    grNumberFilter: boolean,
    setGrNumberFilter: (isGrEmpty: boolean) => void,
}

function CustomToolbar(props: CustomToolbarProps) {
    const { t } = useContext(LayoutContext);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [dateRange, setDateRange] = useState<DateRange<Moment>>([moment().add(-1, "month"), moment()]);

    const openContextMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        setContextMenu({ mouseX: e.clientX, mouseY: e.clientY, });
    }, []);

    const closeMenu = () => {
        setContextMenu(null);
        setDateRange([moment().add(-1, "month"), moment()]);
    };

    const exportData = async () => {
        const controller = new AbortController();
        const queryParams = new URLSearchParams();
        dateRange[0] && queryParams.append("from", dateRange[0].format("YYYY-MM-DD"));
        dateRange[1] && queryParams.append("to", dateRange[1].format("YYYY-MM-DD"));
        const exportedData = await fetchFileGet(`${apiUrl}/wh-receiving/v1/receiving/export?${queryParams.toString()}`, controller.signal);
        const fileName = `WhReceiving_${dateRange[0]?.format("DD-MM-YYYY") ?? "WhereItAllBegan"}_${dateRange[1]?.format("DD-MM-YYYY") ?? "ToInfinity"}.xlsx`;
        saveAs(exportedData, fileName);
    };

    const filterGrNumber = () => {
        props.setGrNumberFilter(!props.grNumberFilter);
    };

    return (
        <Fragment>
            <GridToolbarContainer sx={{ mb: "20px" }}>
                <GridToolbarColumnsButton />
                <Button color="primary" size="small" startIcon={<Download />} onClick={openContextMenu} >{t("Export")}</Button>
                <Button color="primary" size="small" startIcon={<Add />} onClick={props.handleClickAddManualReceiving} >{t("ButtonManualReceiving")}</Button>
                {
                    props.rowSelectionModel.length === 1 &&
                    <Button color="secondary" size="small" startIcon={<Edit />} onClick={() => props.handleClickEditReceivedMaterial(props.rowSelectionModel[0].toString())}>{t("ButtonEditMaterial")}</Button>
                }
                <Button color="primary" size="small" startIcon={props.grNumberFilter ? <ToggleOn color="success" /> : <ToggleOff color="action" />} onClick={filterGrNumber}>{t("EmptyGrNo")}</Button>
                <GridToolbarQuickFilter placeholder={t("Search")} sx={{ marginLeft: "auto" }} />
            </GridToolbarContainer>

            <Menu open={contextMenu !== null} anchorReference="anchorPosition" variant="menu"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box sx={{ width: { xs: 300, sm: 320, md: 380, lg: 420, xl: 480 }, px: 2 }} role="presentation">
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h6">{t("Export")}</Typography>
                        </Grid>

                        <Grid item>
                            <IconButton onClick={closeMenu}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <DateRangePicker localeText={{ start: t("From"), end: t("To") }} value={dateRange} onChange={e => setDateRange(e)}
                        slotProps={{ textField: { variant: "standard", margin: "normal" } }} format="DD-MM-YYYY" />

                    <Button variant="contained" onClick={exportData} sx={{ float: "right", my: 1 }}>{t("Download")}</Button>
                </Box>
            </Menu>
        </Fragment>
    );
}

export default function ReceivedMaterialPage() {
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [serverSideModel, setServerSideModel] = useState<GridServerSideModel>({ page: 0, size: 20 });
    const [openAddManualReceivingDialog, setOpenAddManualReceivingDialog] = useState(false);
    const [receivingRecordIdToEdit, setReceivingRecordIdToEdit] = useState("");
    const [attachmentsIdToView, setAttachmentsIdToView] = useState("");

    const { data = {} as ReceivingsContentModel, refetch } = useGetReceivings(serverSideModel);

    const columns: GridColDef[] = useMemo(() => [
        { field: "cpn", headerName: "CPN", width: 180 },
        { field: "materialDescription", headerName: "Description", width: 330 },
        { field: "quantity", headerName: "Qty", width: 80 },
        { field: "receiveDate", headerName: "Date", width: 120 },
        { field: "receiveTime", headerName: "Time", width: 80 },
        { field: "keeper", headerName: "Keeper", width: 150 },
        { field: "grNumber", headerName: "GR No.", width: 150 },
        { field: "remark", headerName: "Remark", width: 200 },
        { field: "iqcStatus", headerName: "IQC Status", width: 150 },
        { field: "iqcRemark", headerName: "IQC Remark", width: 200 },
        { field: "importNumber", headerName: "Import No.", width: 120 },
        { field: "hawb", headerName: "HAWB", width: 150 },
        { field: "damageOf", headerName: "Damage of", width: 100 },
        {
            field: "actions", type: "actions", width: 80, align: "left",
            getActions: (params: any) => {
                if (params.row.attachmentId) {
                    return [
                        <GridActionsCellItem icon={<AttachFile />} title="View attached photos" label="" onClick={() => handleClickViewPhotos(params.row.attachmentId)} />
                    ];
                }

                return [];
            }
        },
        { field: "strategicNumber", headerName: "Strategic No.", width: 150 },
    ] as GridColDef[], []);

    function handleClickViewPhotos(attachmentId: string) {
        setAttachmentsIdToView(attachmentId);
    }

    const handleChangedPaginationModel = useCallback((paginationModel: GridPaginationModel) => {
        setServerSideModel({ ...serverSideModel, page: paginationModel.page });

        setTimeout(() => refetch(), 300);
    }, [serverSideModel]);

    const handleChangedFilterModel = useCallback((filterModel: GridFilterModel) => {
        setServerSideModel({ ...serverSideModel, search: filterModel.quickFilterValues?.toString() ?? undefined });

        setTimeout(() => refetch(), 300);
    }, [serverSideModel]);

    const handleChangedGrNumber = useCallback((isGrEmpty: boolean) => {
        setServerSideModel({ ...serverSideModel, isGrEmpty });
        setTimeout(() => refetch(), 300);
    }, [serverSideModel]);

    function handleClickAddManualReceiving() {
        setOpenAddManualReceivingDialog(true);
    };

    function handleChangedRowSelectionModel(newRowSelectionModel: GridRowSelectionModel) {
        setRowSelectionModel(newRowSelectionModel);
    }

    function handleClickEditReceivedMaterial(selectedReceivingRecordId: string) {
        setReceivingRecordIdToEdit(selectedReceivingRecordId);
    };

    return (
        <Box>
            <Typography variant="h6" color="text.secondary" sx={{ pl: "25px", pt: "15px" }} gutterBottom component="div">Received material</Typography>
            <Box
                sx={{
                    display: "flex", width: "100%", height: { xs: "calc(100vh - 114px)", sm: "calc(100vh - 122px)" }, pt: "10px", pl: "20px", pr: "0px", pb: "5px",
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "600",
                    },
                    "& .row-background--defect": {
                        backgroundColor: "#F5C9C9"
                    },
                    "& .row-background--defect:hover, .row-background--defect.Mui-selected, .row-background--defect.Mui-selected:hover": {
                        backgroundColor: "#EC8F8F!important"
                    }
                }}
            >
                <DataGridPro columns={columns} rows={data.content ?? []} density="compact" sx={{ border: "0px", width: "100%" }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    slotProps={{
                        toolbar: { handleClickAddManualReceiving, rowSelectionModel, handleClickEditReceivedMaterial, grNumberFilter: serverSideModel.isGrEmpty, setGrNumberFilter: handleChangedGrNumber }
                    }}
                    disableColumnFilter
                    pagination
                    paginationMode="server"
                    pageSizeOptions={[20]}
                    paginationModel={{ pageSize: 20, page: data.number ?? 0 }}
                    onPaginationModelChange={handleChangedPaginationModel}
                    filterMode="server"
                    onFilterModelChange={handleChangedFilterModel}
                    rowCount={data?.totalElements ?? 0}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={handleChangedRowSelectionModel}
                    getRowClassName={(params) => !!params.row.damageOf ? "row-background--defect" : ""}
                />

                <CreateDialog open={openAddManualReceivingDialog} setOpen={setOpenAddManualReceivingDialog} />
                <EditDialog recordId={receivingRecordIdToEdit} setRecordId={setReceivingRecordIdToEdit} />
                <ViewPhotosDialog attachmentsId={attachmentsIdToView} setAttachmentsId={setAttachmentsIdToView} />
            </Box>
        </Box>
    );
}